<mat-dialog-content>
  <div>
    <p class="text-subtitle-3">Новый тариф ERP и мобильного приложения</p>
    <div class="mt-5" style="padding-bottom: 30px;">
      <div>
        С 1 октября платежи вырастут до 1500 рублей за ERP и до 1000 рублей за мобильное приложение. Первое списание произойдет 1 ноября.<br><br>

        <b>Остались вопросы?</b><br>
        Уточнить подробности можно у личного менеджера.
      </div>
    </div>

    <div class="bg-white sticky w-full">
      <button (click)="saveToLocalStorage()" class="w-full" color="primary" mat-flat-button type="submit"
        i18n>Ознакомился</button>
    </div>
  </div>
</mat-dialog-content>
