import { EntityModel } from "./entity.models";

export class PSAgreementModel extends EntityModel {
  number: string;
  startDate: string;
  endDate?: string;
  reason?: string;
  status: string;
  inn: string;
  dateCreated: string;
};

export class PSWalletModel extends EntityModel {
  balance: number;
  inn: string;
  autoPaymentType: number;
  isContractorError: boolean;
};

export class PSWalletOperation extends EntityModel {
  dateCreated?: string;
  amount: number | number[];
  source: string | string[];
  status: string | string[];
  type: string | string[];
  vmId: string;
  vmSerial: string;
	isCollapsed: boolean = true;
};

export class CreatePaymentResponse {
  paymentId: string;
  paymentURL?: string;
  errorCode: string;
  orderId: string;
  isChargeSuccess?: boolean;
}

export class VmTarif {
  nameAndSerial: string;
  royaltyStartDatetime: string;
  appStartDatetime: string;
  erpStartDatetime: string;
  calculatedRoyaltyAmount?: string;
  calculatedAppAmount: string;
  calculatedErpAmount: string;
	isCollapsed: boolean = true;
}

export class PaymentCard {
  id: string;
  cardNumber: string;
  isDefault: boolean;
  constructor(id: string, cardNumber: string, isDefault: boolean) {
    this.id = id;
    this.cardNumber = cardNumber;
    this.isDefault = isDefault;
  }
}

export class PSInvoice extends EntityModel {
  dateCreated?: string;
  amount: number;
  status: string;
};
