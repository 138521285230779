/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { brand } from './brand';
import type { city } from './city';
import type { company } from './company';
import type { location_format } from './location_format';
import type { object_link } from './object_link';
import type { product_matrix_item_coffee } from './product_matrix_item_coffee';
import type { vending_machine } from './vending_machine';
import type { vm_group } from './vm_group';

/**
 * Продажи автомата
 */
export type vm_vend = (base_entity & {
    /**
     * Идентификатор
     */
    id: string;
    /**
     * Торговый аппарат
     */
    readonly vendingMachine: (vending_machine | object_link);
    /**
     * Время продажи по автомату
     */
    readonly vmTime?: string;
    /**
     * Время продажи по серверу
     */
    readonly serverTime?: string;
    /**
     * Состав продуктовой матрицы
     */
    readonly productMatrixItem?: (product_matrix_item_coffee | object_link);
    /**
     * Цена
     */
    readonly price: number;
    /**
     * Сумма безнала
     */
    readonly cashlessAmount: number;
    /**
     * Сумма, оплаченная кредитом сервера
     */
    readonly serverAmount: number;
    /**
     * Сумма скидки (положительное число) или наценки (отрицательное число)
     */
    readonly discountAmount: number;
    /**
     * Указывает номер считывателя, с которого был оплачен товар
     */
    readonly readerNumber?: number;
    /**
     * Указывает число, которым данная ячейка кодируется внутри торгового автомата.
     */
    readonly productId: number;
    /**
     * Относительный номер чека
     */
    readonly receipt: number;
    readonly company?: (company | object_link);
    readonly vmGroups?: (vm_group | object_link);
    readonly locationFormats?: (location_format | object_link);
    readonly city?: (city | object_link);
    readonly priceList?: vm_vend.priceList;
    readonly priceListName?: string;
    readonly originVmTime?: string;
    readonly originVmDate?: string;
    readonly brand?: (brand | object_link);
    readonly localTime?: string;
});

export namespace vm_vend {

    export enum priceList {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
    }


}

