import { VendingMachinePageInfoModel } from '../../../../models/vending-machines.models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VendingMachinesRoutingNames } from '@app/pages/vending-machines/vending-machines.routing';
import {
  EEntityList,
  EVendingMachineContextsList,
  IVendingMachineBase,
  VendingMachineInfoModel,
  VendingMachineListModel,
  VendingMachineStateModel,
} from '@shared/models';
import { CardTitleStatusEnum } from './enums/card-title-status.enum';
import { IEquipmentCardModel, IInfoCardModel, IPlacementCardModel } from './models/card.models';
import { ResponseModelsTransformService } from './services/response-models-transform.service';
import { VendingMachineCardService } from './vending-machine-card.service';

@Component({
  selector: 'hoho-vending-machine-card',
  templateUrl: './vending-machine-card.component.html',
  styleUrls: ['./vending-machine-card.component.scss'],
})
export class VendingMachineCardComponent implements OnInit {
  titleStatus: CardTitleStatusEnum;

  @Input() cardType: EVendingMachineContextsList;
  @Input() model: IVendingMachineBase;
  @Input() isDialog: boolean = false;
  @Output() closeEvent = new EventEmitter();
  @Input() link: string;

  EEntityContextsList = EVendingMachineContextsList;
  placementModel: IPlacementCardModel;
  infoModel: IInfoCardModel;
  equipmentModel: IEquipmentCardModel;

  constructor(private vendingMachineCardService: VendingMachineCardService, private router: Router) {}
  ngOnInit(): void {
    switch (this.cardType) {
      case EVendingMachineContextsList.state:
        this.equipmentModel = ResponseModelsTransformService.vmStateModelToEquipmentCardModel(
          this.model as VendingMachineStateModel,
        );
        this.titleStatus = this.vendingMachineCardService.getTitleStatus(this.equipmentModel, this.cardType);
        break;
      case EVendingMachineContextsList.list:
        this.placementModel = ResponseModelsTransformService.vmListModelToPlacementCardModel(
          this.model as VendingMachineListModel,
        );
        this.titleStatus = this.vendingMachineCardService.getTitleStatus(this.placementModel, this.cardType);
        break;
      case EVendingMachineContextsList.address:
        this.placementModel = ResponseModelsTransformService.vmPageInfoModelToPlacementCardModel(
          this.model as VendingMachinePageInfoModel,
        );
        this.titleStatus = this.vendingMachineCardService.getTitleStatus(this.placementModel, this.cardType);
        break;
      case EVendingMachineContextsList.info:
        this.infoModel = ResponseModelsTransformService.vmInfoModelToInfoCardModel(
          this.model as VendingMachineInfoModel,
        );
        this.titleStatus = this.vendingMachineCardService.getTitleStatus(this.infoModel, this.cardType);
        break;
    }
    if (this.model.entity === EEntityList.vending_machine_freezer) {
      this.link = VendingMachinesRoutingNames.snackPage.path + '/' + this.model.id;
    } else {
      this.link = VendingMachinesRoutingNames.page.path + '/' + this.model.id;
    }
  }
  close() {
    this.closeEvent.emit();
  }
}
