import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ListBuilderComponent } from '@app/core/modules/list-builder/list-builder.component';
import { EListBuilderViews, IListBuilderProps } from '@app/core/modules/list-builder/models';
import { EEntityList } from '@app/shared/models';
import { users } from '@src/api';
import { ClientsRoutingNames } from '../../clients.routing';

@Component({
  selector: 'hoho-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit {
  @ViewChild('tableBuilder') tableBuilderComponent: ListBuilderComponent<users>;
  tableBuilderProps: IListBuilderProps<users> = {
    entity: EEntityList.sl_user,
    views: [EListBuilderViews.table],
    openFunc: (entity: users) => {
      this.router.navigate([ClientsRoutingNames.clientPage.path + '/' + entity.id]);
    },
  };
  link: string = ClientsRoutingNames.clientPage.path + '/';
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
