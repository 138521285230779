/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { base_entity } from './base_entity';
import type { object_link } from './object_link';
import type { sl_user } from './sl_user';
import type { vending_machine } from './vending_machine';

/**
 * Заказ
 */
export type manual_writeoff_orders = (base_entity & {
    /**
     * Идентификатор
     */
    id?: number;
    /**
     * Дата создания
     */
    readonly dateCreated?: string;
    readonly slUser?: (sl_user | object_link);
    /**
     * Стоимость продукта
     */
    readonly price?: number;
    /**
     * Количество баллов
     */
    readonly cashbackAmount?: number;
    /**
     * Статус
     */
    readonly status?: manual_writeoff_orders.status;
    readonly paymentType?: string;
    /**
     * Стоимость
     */
    readonly priceRub?: number;
    /**
     * HHR
     */
    readonly hhr?: number;
    readonly productName?: string;
    /**
     * Идентификатор ТМ
     */
    readonly vendingMachineId?: string;
    readonly vendingMachineSerialAndName?: string;
    readonly vendingMachine?: (vending_machine | object_link);
    readonly vendingMachineType?: string;
    /**
     * Кол-во ед. товара
     */
    readonly goodsCount?: number;
    readonly vendingMachineName?: string;
    readonly parentOrderId?: number;
    readonly videoStatus?: manual_writeoff_orders.videoStatus;
    /**
     * Тип оплаты
     */
    readonly paymentTypeName?: string;
    readonly reason?: manual_writeoff_orders.reason;
});

export namespace manual_writeoff_orders {

    /**
     * Статус
     */
    export enum status {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
    }

    export enum videoStatus {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
    }

    export enum reason {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
    }


}

