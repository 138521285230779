<a class="custom-card" [routerLink]="link" [ngClass]="{'cursor-pointer ': !isDialog}" *ngIf="model">
  <hoho-card-title *ngIf="titleStatus >= 0" [status]="titleStatus" [model]="model" [isDialog]="isDialog" (closeEvent)="close()"></hoho-card-title>
  <ng-container [ngSwitch]="cardType">
    <hoho-equipment-card [model]="equipmentModel" *ngSwitchCase="EEntityContextsList.state"></hoho-equipment-card>
    <hoho-placement-card [model]="placementModel" *ngSwitchCase="EEntityContextsList.list"></hoho-placement-card>
    <hoho-info-card [model]="infoModel" *ngSwitchCase="EEntityContextsList.info"></hoho-info-card>
    <hoho-address-card [model]="placementModel" *ngSwitchCase="EEntityContextsList.address"></hoho-address-card>
  </ng-container>
  <hoho-machine-location *ngIf="cardType !== EEntityContextsList.address" [model]="model" name="location_sm"></hoho-machine-location>
</a>
