import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAccess } from '@app/core/modules/list-builder/models';
import { EListBuilderViews, IListBuilderProps } from '@core/modules/list-builder/models/list-builder.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ICrumb } from '@shared/@vex/components/breadcrumbs/breadcrumbs.component';
import { ITabContent } from '@shared/components/feature-tabs/feature-tabs.models';
import { EEntityList, EVendingMachineContextsList, IUserActiveModule, IVendingMachineBase } from '@shared/models';
import { EModules, EVendingMachineFeatures, ModuleFeatureType } from '@shared/models/module.models';
import { UserService } from '@shared/services';
import { VendingMachinesRoutingNames } from '../../vending-machines.routing';

@UntilDestroy()
@Component({
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit, AfterViewInit {
  features: string[];
  listBuilderProps: IListBuilderProps<IVendingMachineBase> = {
    entity: EEntityList.vending_machine,
    views: [EListBuilderViews.table, EListBuilderViews.grid, EListBuilderViews.map],
    openFunc: (vm: IVendingMachineBase) => {
      this.router.navigate([
        vm.entity === EEntityList.vending_machine_coffee
          ? VendingMachinesRoutingNames.page.path + '/' + vm.id
          : VendingMachinesRoutingNames.snackPage.path + '/' + vm.id,
      ]);
    },
  };
  link: string = VendingMachinesRoutingNames.page.path + '/';
  EVendingMachineFeatures = EVendingMachineFeatures;
  EModules = EModules;
  activeFeature: EVendingMachineFeatures;
  title = $localize`Мои автоматы`;

  vmAccess: IAccess;
  crumbs: ICrumb[];
  tabsContentList: ITabContent[] = [];
  eVendingMachineContextsList = EVendingMachineContextsList;
  @ViewChild('information') informationTemp: TemplateRef<HTMLElement>;
  @ViewChild('state') stateTemp: TemplateRef<HTMLElement>;
  @ViewChild('list') listTemp: TemplateRef<HTMLElement>;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const moduleInfo: IUserActiveModule = this.userService.modules.find(
      (i) => i.module === EModules.VendingMachineModule,
    );
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res.tab) {
        this.activeFeature = moduleInfo.features.find((i) => i.toLowerCase() === res.tab) as EVendingMachineFeatures;
      }
      this.cd.markForCheck();
    });
    this.vmAccess = this.userService.Access(EEntityList.vending_machine);
  }

  ngAfterViewInit(): void {
    this.tabsContentList = [
      { name: $localize`Информация`, template: this.informationTemp, feature: EVendingMachineFeatures.Information },
      { name: $localize`Состояние автоматов`, template: this.stateTemp, feature: EVendingMachineFeatures.State },
      { name: $localize`Список`, template: this.listTemp, feature: EVendingMachineFeatures.List },
    ];
  }

  goToCreateVending(): void {
    this.router.navigate([VendingMachinesRoutingNames.create.path]);
  }

  goToCreateSnack(): void {
    this.router.navigate([VendingMachinesRoutingNames.createSnack.path]);
  }

  changeTab(feat: ModuleFeatureType): void {
    let url: string;
    switch (feat) {
      case EVendingMachineFeatures.Information:
        url = VendingMachinesRoutingNames.information.path;
        break;
      case EVendingMachineFeatures.List:
        url = VendingMachinesRoutingNames.list.path;
        break;
      case EVendingMachineFeatures.State:
        url = VendingMachinesRoutingNames.state.path;
        break;
    }

    this.router.navigate([url], { replaceUrl: true });
  }
}
