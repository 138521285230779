import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VendingMachineDialogCardComponent } from '@core/modules/vending-machine-card/components/vending-machine-dialog-card/vending-machine-dialog-card.component';
import { VexModule } from '@shared/@vex/vex.module';
import { ComponentsModule } from '@shared/components/components.module';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { EquipmentCardComponent } from './components/equipment-card/equipment-card.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MachineCommunicationLevelComponent } from './components/machine-communication-level/machine-communication-level.component';
import { MachineFullnessOfIngredientsComponent } from './components/machine-fullness-of-ingredients/machine-fullness-of-ingredients.component';
import { MachineLocationComponent } from './components/machine-location/machine-location.component';
import { MachineStateComponent } from './components/machine-state/machine-state.component';
import { PlacementCardComponent } from './components/placement-card/placement-card.component';
import { CardTitleComponent } from './components/title/title.component';
import { VendingMachineCardComponent } from './vending-machine-card.component';
import { VendingMachineCardService } from './vending-machine-card.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    InfoCardComponent,
    PlacementCardComponent,
    EquipmentCardComponent,
    CardTitleComponent,
    MachineStateComponent,
    MachineLocationComponent,
    MachineFullnessOfIngredientsComponent,
    MachineCommunicationLevelComponent,
    VendingMachineCardComponent,
    VendingMachineDialogCardComponent,
    AddressCardComponent,
  ],
  imports: [CommonModule, ComponentsModule, VexModule, MatTooltipModule, RouterModule],
  exports: [
    VendingMachineCardComponent,
    MachineStateComponent,
    MachineCommunicationLevelComponent,
    MachineFullnessOfIngredientsComponent,
  ],
  providers: [VendingMachineCardService],
})
export class VendingMachineCardModule {}
